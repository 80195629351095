import { default as auth7w1dpZON5OMeta } from "/app/cms_public/node_modules/.pnpm/@apptegy+nuxt-auth@2.1.0/node_modules/@apptegy/nuxt-auth/dist/runtime/pages/docs/auth.vue?macro=true";
import { default as indexWvC3cZfIPWMeta } from "/app/cms_public/pages/article/[id]/index.vue?macro=true";
import { default as index2j3TNwqkz6Meta } from "/app/cms_public/pages/athletics/index.vue?macro=true";
import { default as indexViJtghiAUKMeta } from "/app/cms_public/pages/browse/[id]/index.vue?macro=true";
import { default as indexwbEN7OHlJ3Meta } from "/app/cms_public/pages/dining/index.vue?macro=true";
import { default as _91_46_46_46slug_936kRdHVwkFHMeta } from "/app/cms_public/pages/documents/[...slug].vue?macro=true";
import { default as indexnXyUdDfPATMeta } from "/app/cms_public/pages/documents/index.vue?macro=true";
import { default as indextMMq2jWDibMeta } from "/app/cms_public/pages/events/index.vue?macro=true";
import { default as _91_91uuid_93_93roi2RsEsn5Meta } from "/app/cms_public/pages/forms/[[id]]/[[uuid]].vue?macro=true";
import { default as indexDChjb6mAaaMeta } from "/app/cms_public/pages/index.vue?macro=true";
import { default as indexyzk6WsVcCCMeta } from "/app/cms_public/pages/intranet/login/index.vue?macro=true";
import { default as indexsG7WtFkdjHMeta } from "/app/cms_public/pages/live-feed/index.vue?macro=true";
import { default as indexP5jpXupdUtMeta } from "/app/cms_public/pages/news/index.vue?macro=true";
import { default as page_45mixinsgEE9gat4BkMeta } from "/app/cms_public/pages/page-mixins.js?macro=true";
import { default as indexd1j47fKN3uMeta } from "/app/cms_public/pages/page-preview/[slug]/index.vue?macro=true";
import { default as indexfD5OfpVNGtMeta } from "/app/cms_public/pages/page/[slug]/index.vue?macro=true";
import { default as indexNEkkUuvYRwMeta } from "/app/cms_public/pages/preview/[token]/[id]/index.vue?macro=true";
import { default as indexxuGgwmeeckMeta } from "/app/cms_public/pages/staff/index.vue?macro=true";
export default [
  {
    name: "DocsAuth",
    path: "/__docs/auth",
    component: () => import("/app/cms_public/node_modules/.pnpm/@apptegy+nuxt-auth@2.1.0/node_modules/@apptegy/nuxt-auth/dist/runtime/pages/docs/auth.vue")
  },
  {
    name: "article-id",
    path: "/article/:id()",
    component: () => import("/app/cms_public/pages/article/[id]/index.vue")
  },
  {
    name: "athletics",
    path: "/athletics",
    component: () => import("/app/cms_public/pages/athletics/index.vue")
  },
  {
    name: "browse-id",
    path: "/browse/:id()",
    meta: indexViJtghiAUKMeta || {},
    component: () => import("/app/cms_public/pages/browse/[id]/index.vue")
  },
  {
    name: "dining",
    path: "/dining",
    component: () => import("/app/cms_public/pages/dining/index.vue")
  },
  {
    name: "documents-slug",
    path: "/documents/:slug(.*)*",
    component: () => import("/app/cms_public/pages/documents/[...slug].vue")
  },
  {
    name: "documents",
    path: "/documents",
    component: () => import("/app/cms_public/pages/documents/index.vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/app/cms_public/pages/events/index.vue")
  },
  {
    name: "forms-id-uuid",
    path: "/forms/:id?/:uuid?",
    component: () => import("/app/cms_public/pages/forms/[[id]]/[[uuid]].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/cms_public/pages/index.vue")
  },
  {
    name: "intranet-login",
    path: "/intranet/login",
    meta: indexyzk6WsVcCCMeta || {},
    component: () => import("/app/cms_public/pages/intranet/login/index.vue")
  },
  {
    name: "live-feed",
    path: "/live-feed",
    component: () => import("/app/cms_public/pages/live-feed/index.vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/app/cms_public/pages/news/index.vue")
  },
  {
    name: "page-mixins",
    path: "/page-mixins",
    component: () => import("/app/cms_public/pages/page-mixins.js")
  },
  {
    name: "page-preview-slug",
    path: "/page-preview/:slug()",
    meta: indexd1j47fKN3uMeta || {},
    component: () => import("/app/cms_public/pages/page-preview/[slug]/index.vue")
  },
  {
    name: "page-slug",
    path: "/page/:slug()",
    meta: indexfD5OfpVNGtMeta || {},
    component: () => import("/app/cms_public/pages/page/[slug]/index.vue")
  },
  {
    name: "preview-token-id",
    path: "/preview/:token()/:id()",
    meta: indexNEkkUuvYRwMeta || {},
    component: () => import("/app/cms_public/pages/preview/[token]/[id]/index.vue")
  },
  {
    name: "staff",
    path: "/staff",
    component: () => import("/app/cms_public/pages/staff/index.vue")
  },
  {
    name: "alias-article-id",
    path: "/o/:alias/article/:id()",
    component: () => import("/app/cms_public/pages/article/[id]/index.vue")
  },
  {
    name: "alias-athletics",
    path: "/o/:alias/athletics",
    component: () => import("/app/cms_public/pages/athletics/index.vue")
  },
  {
    name: "alias-browse-id",
    path: "/o/:alias/browse/:id()",
    meta: indexViJtghiAUKMeta || {},
    component: () => import("/app/cms_public/pages/browse/[id]/index.vue")
  },
  {
    name: "alias-dining",
    path: "/o/:alias/dining",
    component: () => import("/app/cms_public/pages/dining/index.vue")
  },
  {
    name: "alias-documents-slug",
    path: "/o/:alias/documents/:slug(.*)*",
    component: () => import("/app/cms_public/pages/documents/[...slug].vue")
  },
  {
    name: "alias-documents",
    path: "/o/:alias/documents",
    component: () => import("/app/cms_public/pages/documents/index.vue")
  },
  {
    name: "alias-events",
    path: "/o/:alias/events",
    component: () => import("/app/cms_public/pages/events/index.vue")
  },
  {
    name: "alias-forms-id-uuid",
    path: "/o/:alias/forms/:id?/:uuid?",
    component: () => import("/app/cms_public/pages/forms/[[id]]/[[uuid]].vue")
  },
  {
    name: "alias-index",
    path: "/o/:alias/",
    component: () => import("/app/cms_public/pages/index.vue")
  },
  {
    name: "alias-intranet-login",
    path: "/o/:alias/intranet/login",
    meta: indexyzk6WsVcCCMeta || {},
    component: () => import("/app/cms_public/pages/intranet/login/index.vue")
  },
  {
    name: "alias-live-feed",
    path: "/o/:alias/live-feed",
    component: () => import("/app/cms_public/pages/live-feed/index.vue")
  },
  {
    name: "alias-news",
    path: "/o/:alias/news",
    component: () => import("/app/cms_public/pages/news/index.vue")
  },
  {
    name: "alias-page-mixins",
    path: "/o/:alias/page-mixins",
    component: () => import("/app/cms_public/pages/page-mixins.js")
  },
  {
    name: "alias-page-preview-slug",
    path: "/o/:alias/page-preview/:slug()",
    meta: indexd1j47fKN3uMeta || {},
    component: () => import("/app/cms_public/pages/page-preview/[slug]/index.vue")
  },
  {
    name: "alias-page-slug",
    path: "/o/:alias/page/:slug()",
    meta: indexfD5OfpVNGtMeta || {},
    component: () => import("/app/cms_public/pages/page/[slug]/index.vue")
  },
  {
    name: "alias-preview-token-id",
    path: "/o/:alias/preview/:token()/:id()",
    meta: indexNEkkUuvYRwMeta || {},
    component: () => import("/app/cms_public/pages/preview/[token]/[id]/index.vue")
  },
  {
    name: "alias-staff",
    path: "/o/:alias/staff",
    component: () => import("/app/cms_public/pages/staff/index.vue")
  }
]