import { computed } from 'vue';
import { useRuntimeConfig, setPageLayout } from '#imports';

export const useIntranet = () => {
  const config = useRuntimeConfig();

  const isIntranet = computed(() => !!config?.public?.IS_INTRANET);
  const handleLayout = () => {
    if (!isIntranet.value) return;

    return setPageLayout('intranet');
  };
  return {
    isIntranet,
    handleLayout,
  };
};
