import { defineNuxtRouteMiddleware, useCookie, navigateTo, useOidcClient } from '#imports';
import { useStore } from 'vuex';
import { useIntranet } from '@/composables/useIntranet';

const authFlowRouteNames = ['intranet-login'];

export default defineNuxtRouteMiddleware(async ({ name, query, path }) => {
  const { isIntranet } = useIntranet();
  if (!isIntranet.value) return;
  if (authFlowRouteNames.includes(name)) return;

  const store = useStore();

  if (import.meta.server) {
    const authCookie = useCookie('auth-token');
    if (!authCookie.value) {
      const redirectPath = useCookie('oauth_redirect_to_url');
      redirectPath.value = path;
      if (query.token) {
        return navigateTo(`/intranet/login?token=${query.token}`);
      }
      if (query.error) {
        return navigateTo(`/intranet/login?error=${query.error}`);
      }
      if (query.alias) {
        store.commit('setAlias', query.alias);
        return navigateTo(`/intranet/login?alias=${query.alias}`);
      }
      if (!authFlowRouteNames.includes(name)) {
        return navigateTo('/intranet/login');
      }
    }
    const redirectPath = useCookie('oauth_redirect_to_url');
    if (authCookie.value && redirectPath.value && redirectPath.value !== path && redirectPath.value !== '/') {
      const temp = redirectPath.value;
      redirectPath.value = undefined;
      return navigateTo(temp);
    }
    return;
  }

  const oidcClient = useOidcClient();
  const user = await oidcClient().getUser();
  if (!user) {
    const redirectPath = useCookie('oauth_redirect_to_url');
    redirectPath.value = path;
    if (query.token) {
      return navigateTo(`/intranet/login?token=${query.token}`);
    }
    if (query.error) {
      return navigateTo(`/intranet/login?error=${query.error}`);
    }
    if (query.alias) {
      store.commit('setAlias', query.alias);
      return navigateTo(`/intranet/login?alias=${query.alias}`);
    }
    if (!authFlowRouteNames.includes(name)) {
      return navigateTo('/intranet/login');
    }
  }
  const redirectPath = useCookie('oauth_redirect_to_url');
  if (user && redirectPath.value && redirectPath.value !== path  && redirectPath.value !== '/') {
    const temp = redirectPath.value;
    redirectPath.value = undefined;
    return navigateTo(temp);
  }
  return;
});
