<template>
  <div class="intranet-page">
    <div class="login-form-card">
      <ts-card class="card-wrapper">
        <div class="card-content">
          <div class="school-information">
            <img
              :src="orgData?.logo"
              alt="School Logo"
              class="school-logo"
            />
            <h2>{{ orgData?.name }}</h2>
            <p>{{ orgData?.tag_line }}</p>
          </div>
          <h3>{{ $t('intranet.signInToIntranet') }}</h3>
          <p
            class="error-message"
            v-if="hasError || error"
          >
            {{ $t('intranet.invalidLoginOrPassword') }}
          </p>
          <ts-form-input
            id="login-email-input"
            :label="$t('intranet.emailAddress')"
            placeholder="e.g. johndoe@email.com"
            class="input-field"
            type="text"
            v-model="email"
            required
            @keyup.enter="login"
          />
          <ts-form-input
            id="login-password-input"
            :placeholder="$t('intranet.password')"
            type="password"
            :label="$t('intranet.password')"
            required
            v-model="password"
            @keyup.enter="login"
            class="input-field"
          />
          <a
            :href="forgotPasswordUrl"
            class="forgot-button"
          >
            {{ $t('intranet.forgotYourPassword') }}
          </a>
          <ts-button
            variant="primary"
            @click="login"
            class="login-button"
          >
            {{ $t('intranet.signIn') }}
          </ts-button>
          <p>or</p>
          <div class="provider-buttons">
            <a
              :href="getProviderUrl('microsoft_graph')"
              external
            >
              <ts-button
                variant="tertiary"
                width="328px"
              >
                <div class="provider-button">
                  <MicrosoftIcon />
                  <p>{{ $t('intranet.microsoftSignIn') }}</p>
                </div>
              </ts-button>
            </a>
            <a
              :href="getProviderUrl('google_oauth2')"
              external
            >
              <ts-button
                variant="tertiary"
                width="328px"
              >
                <div class="provider-button">
                  <GoogleIcon />
                  <p>{{ $t('intranet.googleSignIn') }}</p>
                </div>
              </ts-button>
            </a>
          </div>
        </div>
      </ts-card>
      <div class="privacy-links">
        <a
          :href="termsOfUseLink"
          class="privacy-link"
          target="_blank"
        >
          {{ $t('intranet.termsOfUse') }}
        </a>
        <a
          :href="privacyPolicyLink"
          class="privacy-link"
          target="_blank"
        >
          {{ $t('intranet.privacyPolicy') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../../../components/intranet/dscl/Button.vue';
import Card from '../../../components/intranet/dscl/Card.vue';
import FormInput from '../../../components/intranet/dscl/FormInput.vue';
import GoogleIcon from '../../../components/intranet/dscl/GoogleIcon.vue';
import MicrosoftIcon from '../../../components/intranet/dscl/MicrosoftIcon.vue';
import { User } from 'oidc-client-ts';
import { useStore } from 'vuex';
import { useIntranet } from '@/composables/useIntranet.js';

export default defineNuxtComponent({
  name: 'IntranetLogin',
  components: {
    'ts-button': Button,
    'ts-card': Card,
    'ts-form-input': FormInput,
    GoogleIcon,
    MicrosoftIcon,
  },
  async setup() {
    definePageMeta({
      layout: 'login',
    });

    const { isIntranet } = useIntranet();
    if (!isIntranet.value) return navigateTo('/');

    const hasError = ref(false);
    const route = useRoute();
    const store = useStore();
    const error = ref(false);
    onMounted(async () => {
      const $axios = useNuxtApp().$axios;
      if (store.state.needsRefresh) {
        store.commit('setNeedsRefresh', false);
        import.meta.server ? navigateTo('/intranet/login') : window.location.reload();
        return;
      }
      if (route.query.token) {
        try {
          const { data } = await $axios.get(`${useRuntimeConfig().public.APPTEGY_OIDC_URL}/oauth/userinfo`, {
            headers: {
              Authorization: `Bearer ${route.query.token}`,
            },
          });
          const authCookie = useCookie('auth-token');
          authCookie.value = `Bearer ${route.query.token}`;
          const oidcClient = useOidcClient();
          const userModel = new User({
            profile: {
              ...data,
            },
          });
          await oidcClient().storeUser(userModel);
          route.query.alias ? window.location.replace(`/o/${route.query.alias}`) : window.location.replace('/');
        } catch (error) {
          hasError.value = true;
          console.error(error);
        }
      }
      if (route.query.error) {
        error.value = true;
      }
    });

    const { data, error: fetchError } = await useAsyncData('login', async () => {
      const { data } = await useNuxtApp().$axios.get(`/api/v6/secondary_organizations/info`, {
        params: {
          domain: store.state.domain,
          ...(route.query.alias && { alias: route.query.alias }),
        },
      });
      return data.data;
    });
    if (fetchError.value) {
      console.error(fetchError.value);
    }
    const protocol = import.meta.env.DEV ? 'http' : 'https';
    return { orgData: data.value, host: useRequestHeader('host') || window.location.host, protocol, hasError, error };
  },
  data() {
    return {
      email: '',
      password: '',
      termsOfUseLink: 'https://www.apptegy.com/terms-of-use/',
      privacyPolicyLink: 'https://www.apptegy.com/privacy-policy/',
    };
  },
  computed: {
    forgotPasswordUrl() {
      return `${useRuntimeConfig().public.APPTEGY_OIDC_URL}/users/password/new`;
    },
  },
  methods: {
    validateInputs() {
      if (!this.email || !this.password) {
        this.hasError = true;
        throw new Error('Email and password are required');
      }
    },
    async login() {
      try {
        this.validateInputs();
        const res = await this.$axios.post(`${useRuntimeConfig().public.APPTEGY_OIDC_URL}/oauth/token`, {
          username: this.email,
          password: this.password,
          validate_client: true,
          external_id: this.orgData?.parent_organization_id,
          scope: 'openid',
          grant_type: 'password',
          client_id: useRuntimeConfig().public.APPTEGY_OIDC_CLIENT_ID,
        });

        const userInfo = await this.$axios.get(`${useRuntimeConfig().public.APPTEGY_OIDC_URL}/oauth/userinfo`, {
          headers: {
            Authorization: `Bearer ${res.data.id_token}`,
          },
        });

        const authCookie = useCookie('auth-token');
        authCookie.value = `Bearer ${res.data.id_token}`;
        const oidcClient = useOidcClient();
        const userModel = new User({
          ...res.data,
          profile: {
            ...userInfo.data,
          },
        });
        await oidcClient().storeUser(userModel);
        window.location.replace('/');
      } catch (error) {
        console.error(error);
        this.hasError = true;
      }
    },
    getProviderUrl(provider) {
      return `${useRuntimeConfig().public.APPTEGY_OIDC_URL}/users/auth/${provider}?origin=${this.protocol}://${
        this.host
      }/intranet/login&external_id=${this.orgData?.parent_organization_id}&client_id=${
        useRuntimeConfig().public.APPTEGY_OIDC_CLIENT_ID
      }`;
    },
  },
});
</script>

<style lang="scss" scoped>
.intranet-page {
  --color-purple-70: #383147;
  background: linear-gradient(84deg, #383147 58.82%, #503462 90.87%), var(--color-purple-70);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  .login-form-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    .card-wrapper {
      width: 392px;
      height: 686px;
    }

    .school-information {
      text-align: center;
      margin-bottom: 24px;

      .school-logo {
        margin-bottom: 16px;
        width: 80px;
        height: 80px;
        object-fit: contain;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 32px;

      .error-message {
        color: #cc314b;
        font-size: 12px;
      }

      .form-group {
        margin-top: 24px;
        width: 328px;
      }

      .login-button,
      .provider-button {
        width: 328px;
      }

      .login-button {
        height: 48px;
        margin: 16px 0 8px;
      }

      .provider-buttons {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 8px;
        margin: 8px 0;

        .provider-button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;

          p {
            font-size: 13px;
            color: #333;
          }
        }
      }

      .forgot-button {
        text-decoration: none;
        font-size: 14px;
        cursor: pointer;
        color: #662e80;
        text-align: left;
      }
    }

    .privacy-links {
      display: flex;
      gap: 8px;

      .privacy-link {
        font-size: 14px;
        text-decoration: none;
        text-align: center;
        margin-top: 8px;
        color: #f4e3ce;
        cursor: pointer;
      }
    }
  }
}
</style>
